.Counter {
  text-align: right;

  .CounterTitle {
    font-weight: 700;
    font-size: 1.3888vw;
  }

  .CounterAvailable {
    margin: 0.6172vh 0 0 0;
    font-weight: 400;
    font-size: 2.6851vw;
  }
}