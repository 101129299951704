.MainPage {
  position: relative;
  padding: 10.074vh 4.074vh;
  box-sizing: border-box;

  .MainPageTitle {
    margin: 6.1728vh 0 0 0;
    font-weight: 700;
    font-size: 6.2962vw;
    line-height: 1.1;
    letter-spacing: -0.01em;
  }

  .MainPageCounter {
    position: absolute;
    top: 10.074vh;
    right: 4.074vh;
  }

  .MainPageActions {
    margin: 6.1728vh 0 0 0;

    .MainPageActionsDisabled {
      pointer-events: none;
      opacity: 0;
    }
  }

  .MainPageRoll {
    margin: 9.2592vh 0 0 0;

    .MainPageRollWheel {}
  }
}