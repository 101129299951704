html, body {
  background-color: #3E2B87;
}

.Layout {
  position: fixed;
  width: 100vw;
  height: 100vh;
  color: white;
  user-select: none;
  font-weight: normal;
  font-style: normal;
  overflow: hidden;
  background-attachment: fixed;
  background-image: url(./images/background.png);
  background-color: #3E2B87;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;

  &:after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    background-image: url(./images/box.png);
    background-size: contain;
    background-position: bottom right;
    background-repeat: no-repeat;
    width: 38.5664vw;
    height: 100%;
  }

  .LayoutContainer {

  }
}