@font-face {
  font-family: "YS Text";
  src: url("../fonts/YS_Text-Regular.woff2") format("woff2"),
  url("../fonts/YS_Text-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "YS Text";
  src: url("../fonts/YS_Text-Bold.woff2") format("woff2"),
  url("../fonts/YS_Text-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

html, body {
  font-family: 'YS Text', sans-serif;
  font-weight: 400;
  font-style: normal;
}