.Wheel {
  position: relative;
  width: 97.5308vh;
  height: 97.5308vh;
  background-color: #F5F7FF;
  border-radius: 50%;
  border: 2.4567vh solid white;

  .slice {
    position: absolute;
    top: 0;
    left: 50%;
    width: 29.7222vw;
    height: 55.3703vh;
    background-image: url(./images/slice.svg);
    transform: translate(-50%, -10vh);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 10;
  }

  .WheelContainer {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    &.WheelWait {
      animation: spin 30s linear infinite;
    }

    &.WheelAction {
      animation: spin 8s cubic-bezier(0.16, 0.12, 0.1, 1.08) forwards;
    }

    .center {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 6.7901vh;
      height: 6.7901vh;
      border-radius: 50%;
      background-color: white;
    }

    .line {
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 1.2345vh;
      background-color: white;
    }

    .line1 {
      transform: rotate(22.5deg);
    }

    .line2 {
      transform: rotate(67.5deg);
    }

    .line3 {
      transform: rotate(112.5deg);
    }

    .line4 {
      transform: rotate(157.5deg);
    }

    .label {
      position: absolute;
      top: 50%;
      left: 50%;
      height: 75.5308vh;
      font-size: 3.8271vh;
      font-weight: 700;
      color: #3F68F9;
    }

    .label1 {
      transform: translate(-50%, -50%);
    }

    .label2 {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .label3 {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    .label4 {
      transform: translate(-50%, -50%) rotate(135deg);
    }

    .label5 {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    .label6 {
      transform: translate(-50%, -50%) rotate(225deg);
    }

    .label7 {
      transform: translate(-50%, -50%) rotate(270deg);
    }

    .label8 {
      transform: translate(-50%, -50%) rotate(315deg);
    }
  }

  @keyframes spin {
    100% {
      transform: rotate(var(--rotate-angle));
    }
  }
}