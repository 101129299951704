.CodePopup {
  padding: 7.2839vh;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(100px);
  z-index: 10;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  animation: zoom-background 200ms linear;

  .CodePopupContainer {
    padding: 6.1728vh;
    width: calc(100% - 14.5678vh);
    border-radius: 32px;
    color: #000000;
    background-color: #F5F7FF;
    background-image: url(./images/box_2.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: 90vw;
    animation: zoom-content 200ms linear;

    .CodePopupTitle {
      font-weight: 700;
      font-size: 5.3703vh;
      line-height: 1;
    }

    .CodePopupCode {
      margin: 6.1728vh 0 0 0;
      display: flex;
      flex-direction: row;
      align-items: center;

      .CodePopupCodeQR {
        padding: 15px;
        display: block;
        width: 22.3456vh;
        height: 22.3456vh;
        border-radius: 65px;
        border: 5px solid;
        border-image-slice: 1;
        border-image-source: linear-gradient(90deg, #FF5C4D 0%, #EB469F 25.15%, #8341EF 72.39%, #3F68F9 100%);
      }

      .CodePopupCodeTitle {
        margin: 0 0 0 2.6543vh;
        font-weight: 400;
        font-size: 4.8148vh;
      }
    }

    .CodePopupCodeRedeem {
      margin: 6.9753vh 0 0 0;
      min-width: 41.4351vw;
    }
  }

  @keyframes zoom-background {
    0% {
      opacity: 0.6;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes zoom-content {
    0% {
      transform: scale(0.8);
    }

    100% {
      transform: scale(1);
    }
  }
}