.Button {
  padding: 1.7129vw 3.6111vw;
  font-size: 1.8518vw;
  line-height: 1.15;
  color: white;
  background: linear-gradient(90deg, #FF5C4D 0%, #EB469F 30%, #8341EF 75%, #3F68F9 100%);
  border-radius: 18px;
  outline: 0;
  border: 0;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }

  &.ButtonDisabled {
    opacity: 0.6;
    pointer-events: none;
  }
}